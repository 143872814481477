<template>
  <div class="agency-entrustment-index">
    <div class="agency-entrustment-index-title">
      <div class="title-text">待办委托</div>
      <div class="title-refresh">
        <i class="refresh-icon"></i>
        <div class="refresh-text" @click="refresh">刷新</div>
      </div>
    </div>

    <div class="agency-entrustment-index-empty" v-if="list.length == 0">
      <img :src="require('assets/images/no-document.png')" alt="" />
      <p>暂无信息</p>
    </div>

    <div class="agency-entrustment-index-list" v-else>
      <div
        class="agency-entrustment-index-list-item"
        v-for="(item, index) in list"
        :key="index"
        @click="handleClick(item)"
      >
        <div class="list-item-title">
          <div class="title-orderNum">{{ index + 1 }}</div>
          <div class="title-text">{{ item.taskName }}</div>
        </div>
        <el-progress
          :percentage="item.progress"
          :stroke-width="8"
          :format="format"
          :class="item.overTime == true ? 'yellow' : ''"
        ></el-progress>
        <div class="list-item-time">
          <div
            class="time-text"
            :class="item.overTime == true ? 'fontYellow' : 'fontBlue'"
          >
            {{ item.overTime == true ? "已超时" : "未超时" }}
          </div>
          <i
            class="time-icon"
            :class="item.overTime == true ? 'clock-yellow' : 'clock-blue'"
          ></i>
        </div>
      </div>

      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        :total="total"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getagencyEntrustmentList } from "api/copyrightService";
export default {
  name: "AgencyEntrustment",
  data() {
    return {
      total: 100, //总条数
      page: 1, //页码
      limit: 10, //一页10条数据
      list: [],
    };
  },
  methods: {
    format(percentage) {
      return `完成度${percentage}%`;
    },
    currentChange(cur) {
      this.page = cur;
      this.getList();
    },
    handleClick(item) {
      if (item.identityType == 1) {
        //发起人  1    承办人  2
        this.$router.push({
          name: "AgencyEntrustmentDetailsUser",
          query: {
            taskId: item.taskId,
            identityType: item.identityType,
          },
        });
      } else {
        this.$router.push({
          name: "AgencyEntrustmentDetailsContractor",
          query: {
            taskId: item.taskId,
            identityType: item.identityType,
          },
        });
      }
    },
    async getList() {
      let data = {
        page: this.page,
        limit: this.limit,
      };
      let res = await getagencyEntrustmentList(data);
      if (res.code === 200) {
        this.list = res.data.data;
        this.total = res.data.total;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    refresh() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.agency-entrustment-index {
  position: relative;
  height: 871px;
  &-title {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    padding-right: 21px;
    border-bottom: 1px solid #f9f9f9;
    .title-text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .title-refresh {
      display: flex;
      align-items: center;
      cursor: pointer;
      .refresh-icon {
        width: 15px;
        height: 14px;
        @include backgroundGroup("../../../../assets/images/refresh-gray.png");
        margin-right: 5px;
      }
      .refresh-text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
    }
  }
  &-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    p {
      margin-top: 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  &-list {
    margin-left: 20px;
    padding-right: 21px;
    &-item {
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f9f9f9;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      .list-item-title {
        display: flex;
        .title-orderNum {
          width: 20px;
        }
        .title-text {
          width: 298px;
          @include ellipsis();
        }
      }
      .yellow {
        ::v-deep .el-progress-bar__inner {
          background-color: #ffc643;
        }
        ::v-deep .el-progress-bar__outer {
          background-color: #ffeec9;
        }
      }
      ::v-deep .el-progress {
        margin-left: 171px;
        width: 200px;
        display: flex;
        .el-progress__text {
          margin-left: 15px;
          font-size: 12px !important;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          width: 88px;
        }
      }
      .list-item-time {
        display: flex;
        align-items: center;
        margin-left: 255px;
        .time-text {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          margin-right: 5px;
        }
        .fontBlue {
          color: #ff6900;
        }
        .fontYellow {
          color: #fab619;
        }
        .time-icon {
          width: 20px;
          height: 20px;
        }
        .clock-blue {
          @include backgroundGroup("../../../../assets/images/clock-blue.png");
        }
        .clock-yellow {
          @include backgroundGroup(
            "../../../../assets/images/clock-yellow.png"
          );
        }
      }
    }
    ::v-deep .el-pagination {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      .btn-prev {
        width: 70px;
      }
      .btn-next {
        width: 70px;
      }
    }
  }
}
</style>
